import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

import ImgDivider from '@/assets/border/divider.png';

import WithAnimation from '@components/Common/WithAnimation';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import Image from '@components/Common/LazyImage';

import useShiftTime from '@/hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import txtWording from './locales';
import useLang from '@hooks/useLang';

import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  // WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_TIME,
} from '@/constants';
import { IMG_LOGO_WEDDING } from '@/constants/assets';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';
import BorderFrame from '@components/Common/BorderFrame';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const shiftTime = useShiftTime();
  const isInvitation = useInvitation();
  const defaultLang = useLang();
  const lang = defaultLang;

  /** Render Wedding Information */
  const renderWeddingInfo = ({ title, desc, date, time, note, dresscode, place, placeRoad }) => {
    return (
      <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
        <WithAnimation>
          <>  
            <Text
              fontWeight="bold"
              fontSize="xl"
              margin="24px 0"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Text m="16px 0">{desc}</Text>
            <Text fontWeight="bold"><span dangerouslySetInnerHTML={{ __html: date }} /></Text>
            <Text fontWeight="bold">{time}</Text>
            {dresscode && (
              <Text fontWeight="bold">{dresscode}</Text>
            )}
            {note && (
              <Text fontSize="xs" fontStyle="italic">{note}</Text>
            )}
            {place && <Text fontWeight="bold" marginTop="12px" dangerouslySetInnerHTML={{ __html: place }} />}
            {placeRoad && <Text fontSize="xs" dangerouslySetInnerHTML={{ __html: placeRoad }} />}
          </>
        </WithAnimation>
      </Box>
    );
  };

  return (
    <Box bgColor="bgPrimary" padding="32px" pos={"relative"}>
      <BorderFrame isHeader width="calc(100% - 64px)"/>
      <Box
        {...rest}
        padding="42px 24px"
        width="100%"
        position="relative"
      >
        <BorderFrame height="calc(100% - 84px)" width="calc(100%)" top="42px" left="0"/>
        <Box>
          <WithAnimation>
            <Center>
              <Image src={IMG_LOGO_WEDDING} maxWidth="200px"/>
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Box
              marginTop="8px"
              fontFamily="body"
              textAlign="center"
              color="mainColorText"
              >
              <Text fontSize="md" >
                The greatest thing you’ll ever learn <br />
                is to love and be loved in return.
              </Text>
              <Text fontSize="md">- Natalie Cole -</Text>
            </Box>
          </WithAnimation>
          {/* Box Reception Information */}
          <Box>
            <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
              {MUSLIM_INVITATION && 
                <>
                  {renderWeddingInfo ({
                    title: txtWording.akadMuslim[lang],
                    desc: isInvitation
                      ? txtWording.descAkadInvitationMuslim[lang]
                      : txtWording.descAkadMuslim[lang],
                    date: WEDDING_AKAD_FULLDATE,
                    time: isInvitation ? WEDDING_AKAD_TIME : '',
                    place: WEDDING_AKAD_LOC_NAME,
                    placeRoad: isInvitation ? WEDDING_AKAD_LOC_ROAD : '',
                  })}
                </>
              }

              {/* Announcement */}
              {!MUSLIM_INVITATION && !isInvitation &&
                <> 
                  {renderWeddingInfo ({
                    title: txtWording.akadGeneral[lang],
                    desc: txtWording.descAkadGeneral[lang],
                    date: `Friday, December 22nd, 2023`,
                    time: WEDDING_AKAD_TIME,
                    place: false && WEDDING_AKAD_LOC_NAME,
                    placeRoad: false && WEDDING_AKAD_LOC_ROAD,
                  })} 
                </>
              }

              {/* General Invitation */}
              {isInvitation && shiftTime ==="Shift" &&
                renderWeddingInfo({
                  title: txtWording.reception[lang],
                  desc: txtWording.descReception[lang],
                  date: `Saturday, December 23rd, 2023`,
                  time: WEDDING_RESEPSI_TIME,
                  dresscode: `Dresscode: Semi Formal`,
                  note: `*) Park in the next yard.`,
                  place: WEDDING_RESEPSI_LOC_NAME,
                  placeRoad: WEDDING_RESEPSI_LOC_ROAD,
                })
              }
              
              {/* Shift 1 Invitation Resepsi */}
              {isInvitation && shiftTime ==="Resepsi" &&
                renderWeddingInfo({
                  title: txtWording.reception[lang],
                  desc: txtWording.descReception[lang],
                  date: `Saturday, December 23rd, 2023`,
                  time: WEDDING_RESEPSI_TIME,
                  dresscode: `Dresscode: Semi Formal`,
                  note: `*) Park in the next yard.`,
                  place: WEDDING_RESEPSI_LOC_NAME,
                  placeRoad: WEDDING_RESEPSI_LOC_ROAD,
                })
              }
              
              {/* Shift 2 Invitation Pemberkatan & Resepsi */}
              {!MUSLIM_INVITATION && isInvitation && shiftTime ==="Pemberkatan-Resepsi" &&
                <> 
                  {renderWeddingInfo ({
                    title: txtWording.akadGeneral[lang],
                    desc: txtWording.descAkadInvitationGeneral[lang],
                    date: `Friday, December 22nd, 2023`,
                    time: WEDDING_AKAD_TIME,
                    dresscode: `Dresscode: Formal`,
                    place: WEDDING_AKAD_LOC_NAME,
                    placeRoad: WEDDING_AKAD_LOC_ROAD,
                  })} 
                </>
              }
              {isInvitation && shiftTime ==="Pemberkatan-Resepsi" && (
                <WithAnimation>
                  <Center>
                    <Image src={ImgDivider} maxWidth="100%" marginTop="24px" />
                  </Center>
                </WithAnimation>
              )}
              {isInvitation && shiftTime ==="Pemberkatan-Resepsi" &&
                renderWeddingInfo({
                  title: txtWording.reception[lang],
                  desc: txtWording.descReception[lang],
                  date: `Saturday, December 23rd, 2023`,
                  time: WEDDING_RESEPSI_TIME,
                  dresscode: `Dresscode: Semi Formal`,
                  note: `*) Park in the next yard.`,
                  place: WEDDING_RESEPSI_LOC_NAME,
                  placeRoad: WEDDING_RESEPSI_LOC_ROAD,
                })
              }
            </Box>
          </Box>
          
          {/* BOX Button View Location */}
          {isInvitation && (
            <WithAnimation>
              <Center>
                <ViewLocationButton
                  marginTop="16px"
                  size="sm"
                  fontWeight="normal"
                  bgColor="bgSecondary"
                  color="btnMainColor"
                  text={txtWording.location[lang]}
                  {...DEFAULT_BUTTON_PROPS}
                />
              </Center>
            </WithAnimation>
          )}
          <BorderFrame isFooter left={"0"}/>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WeddingSection);