import React from 'react';
import { object, string } from 'prop-types';

import { Box, Center, Heading, Text, Divider } from '@chakra-ui/react';
import Image from '@components/Common/LazyImage';
import InstagramButton from '@components/Common/InstagramButton';
import WithAnimation from '@components/Common/WithAnimation';
import useLang from '@hooks/useLang';

import { IMG_PHOTO_COVER } from '@/constants/assets';

function DetailCouple(props) {
  const defaultLang = useLang();
  const lang = defaultLang;
  const {
    imgUrl,
    instagramId,
    parentName,
    fullName,
    shortName,
    shortNameProps,
    instagramIdProps,
    fullNameProps,
    imgUrlProps,
    parentNameProps,
  } = props;

  return (
    <Box>
      <WithAnimation>
        <Center>
          <Box
            margin="24px 0"
            bgImage={`url(${IMG_PHOTO_COVER})`}
            height="280px"
            width="280px"
            padding="20px"
            bgRepeat="no-repeat"
            bgSize="contain"
          >
            <Image
              src={imgUrl}
              height="240px"
              width="240px"
              objectFit="cover"
              objectPosition="center"
              borderRadius="16px"
              marginBottom="16px"
              {...imgUrlProps}
            />
          </Box>
        </Center>
      </WithAnimation>
      <WithAnimation>
        <Heading
          color="mainColorText"
          textAlign="center"
          fontSize="6xl"
          fontWeight="normal"
          {...shortNameProps}
        >
          {shortName}
        </Heading>
      </WithAnimation>
      <Center>
        <Divider
          margin="12px 0 16px"
          maxW="80px"
          opacity={1}
          borderColor="white"
          borderBottomWidth="2px"
        />
      </Center>
      <WithAnimation>
        <Text
          color="mainColorText"
          fontSize="xl"
          textAlign="center"
          margin="4px 0"
          {...fullNameProps}
        >
          {fullName}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Text
          color="mainColorText"
          textAlign="center"
          fontSize="sm"
          dangerouslySetInnerHTML={{ __html: parentName[lang] }}
          {...parentNameProps}
        />
      </WithAnimation>
      <Center marginTop="24px">
        <WithAnimation>
          <InstagramButton
            id={instagramId}
            text={instagramId}
            backgroundColor="bgSecondary"
            color="btnMainColor"
            fontSize="sm"
            onlyIcon={false}
            fontFamily="body"
            fontWeight="normal"
            {...instagramIdProps}
          />
        </WithAnimation>
      </Center>
    </Box>
  );
}

DetailCouple.propTypes = {
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  parentName: string.isRequired,
  fullName: string.isRequired,
  shortName: string.isRequired,
  shortNameProps: object,
  instagramIdProps: object,
  fullNameProps: object,
  imgUrlProps: object,
  parentNameProps: object,
};

DetailCouple.defaultProps = {
  shortNameProps: {},
  instagramIdProps: {},
  fullNameProps: {},
  imgUrlProps: {},
  parentNameProps: {},
};

export default DetailCouple;
