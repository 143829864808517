import P1 from '@/images/2/1.jpg';
import P2 from '@/images/2/2.jpg';
import P3 from '@/images/2/3.jpg';
import P4 from '@/images/2/4.jpg';
import P5 from '@/images/2/5.jpg';
import P6 from '@/images/2/6.jpg';
import P7 from '@/images/2/7.jpg';
import P8 from '@/images/2/8.jpg';
import P9 from '@/images/2/9.jpg';
import P10 from '@/images/2/10.jpg';
import P11 from '@/images/2/11.jpg';
import P12 from '@/images/2/12.jpg';
import P13 from '@/images/2/13.jpg';
import P14 from '@/images/2/14.jpg';
import P15 from '@/images/2/15.jpg';
import P16 from '@/images/2/16.jpg';
import P17 from '@/images/2/17.jpg';
import P18 from '@/images/2/18.jpg';
import P19 from '@/images/2/19.jpg';
import P20 from '@/images/2/20.jpg';
import P21 from '@/images/2/21.jpg';
import P22 from '@/images/2/22.jpg';
import P23 from '@/images/2/23.jpg';
import P24 from '@/images/2/24.jpg';
import P25 from '@/images/2/25.jpg';
import P26 from '@/images/2/26.jpg';
import P27 from '@/images/2/27.jpg';
import P28 from '@/images/2/28.jpg';

export const photos = [
  {
    original: P1,
    // thumbnail: P1,
  },
  {
    original: P2,
    // thumbnail: P2,
  },
  {
    original: P3,
    // thumbnail: P3,
  },
  {
    original: P4,
    // thumbnail: P4,
  },
  {
    original: P5,
    // thumbnail: P5,
  },
  {
    original: P6,
    // thumbnail: P6,
  },
  {
    original: P7,
    // thumbnail: P7,
  },
  {
    original: P8,
    // thumbnail: P8,
  },
  {
    original: P9,
    // thumbnail: P8,
  },
  {
    original: P10,
    // thumbnail: P8,
  },
  {
    original: P11,
    // thumbnail: P1,
  },
  {
    original: P12,
    // thumbnail: P2,
  },
  {
    original: P13,
    // thumbnail: P3,
  },
  {
    original: P14,
    // thumbnail: P4,
  },
  {
    original: P15,
    // thumbnail: P5,
  },
  {
    original: P16,
    // thumbnail: P6,
  },
  {
    original: P17,
    // thumbnail: P7,
  },
  {
    original: P18,
    // thumbnail: P8,
  },
  {
    original: P19,
    // thumbnail: P8,
  },
  {
    original: P20,
    // thumbnail: P8,
  },
  {
    original: P21,
    // thumbnail: P1,
  },
  {
    original: P22,
    // thumbnail: P2,
  },
  {
    original: P23,
    // thumbnail: P3,
  },
  {
    original: P24,
    // thumbnail: P4,
  },
  {
    original: P25,
    // thumbnail: P5,
  },
  {
    original: P26,
    // thumbnail: P6,
  },
  {
    original: P27,
    // thumbnail: P7,
  },
  {
    original: P28,
    // thumbnail: P8,
  },
];