import get from '@invitato/helpers/dist/getLocale';

export default {
  akadGeneral: get('Pemberkatan', 'Holy Matrimony'),
  akadMuslim: get('Akad Nikah','Akad Nikah'),
  reception: get('Resepsi Pernikahan', 'Wedding Reception'),
  location: get('Lihat Lokasi', 'View Location'),
  descAkadGeneral: get(
    'Berkaitan dengan pembatasan jumlah tamu undangan, maka Pemberkatan akan diselenggarakan secara terbatas pada:', 
    'Holy Matrimony will be held on:'
  ),
  descAkadInvitationGeneral: get(
    'Berkaitan dengan pembatasan jumlah tamu undangan, maka Pemberkatan akan diselenggarakan secara terbatas pada:',
    'We request the presence of Mr/Mrs/Ms in the Holy Matrimony on:'
  ),
  descAkadMuslim: get(
    'Berkaitan dengan pembatasan jumlah tamu undangan, maka Akad Nikah akan diselenggarakan secara terbatas pada:',
    'Due to Restrictions on gathering sizes, the Akad Nikah will be held with a limited capacity on:'
  ),
  descAkadInvitationMuslim: get(
    'Berkaitan dengan pembatasan jumlah tamu undangan, maka Akad Nikah akan diselenggarakan secara terbatas pada:',
    'Due to Restrictions on gathering sizes, the Akad Nikah will be held with a limited capacity on:'
  ),
  descReception: get(
    'Kami bermaksud mengundang Bapak/Ibu/Saudara/i dalam acara Resepsi Pernikahan yang akan diselenggarakan pada:',
    `We request the presence of Mr/Mrs/Ms in the Wedding Reception on:`
  ),
};