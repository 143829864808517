import get from '@invitato/helpers/dist/getLocale';

export default {
  btnGallery: get('Lihat Potret Kami','More Capture of Us'),
  backContent: get('Kembali', 'Back'),

  descIGFilter: get(
    'Abadikan momen Anda selama menghadiri acara pernikahan kami dengan menggunakan filter instagram di bawah:',
    'Capture your moment while attending our wedding by using the Instagram filter below:'),
  btnFilter: get('Gunakan Filter','Use Filter'),
};